var generic = generic || {};
var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };

(function ($) {
  site.signin = {
    isSignedIn: 0,
    $template: '',
    forgotPasswordReturnuser: function (args) {
      if (!args.emailNode.length) {
        args.emailNode = $('#login__email.js-login-email-phone');
      }
      if (args.emailNode.length > 1 || !args.forgotPasswordLink || !args.errorListNode) {
        return null;
      }
      site.signin.getRBKeys();

      var errorListNode = args.errorListNode;
      var emailNode = args.emailNode;
      var forgotPasswordNote = args.forgotPasswordNote;
      var forgotPasswordCopy = $('#lpw-text');
      var panel = args.panel;

      // content may have been set on server side. If so, do not hide.
      if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length < 1) {
        forgotPasswordCopy.hide();
      }

      forgotPasswordNote.delegate('a.js-gnav-forgot-password', 'click', function (evt) {
        evt.preventDefault();
        var email = site.signin.getEmailAddress(emailNode);

        if (!email || email.length < 1) {
          if (panel) {
            panel.setMessages({
              text: site.signin.forgotPasswordNoEmailProvided
            });
          } else {
            generic.showErrors(
              [
                {
                  text: site.signin.forgotPasswordNoEmailProvided
                }
              ],
              errorListNode
            );
            generic.showErrorState(emailNode[0]);
          }

          return null;
        }

        generic.jsonrpc.fetch({
          method: 'user.accountAvailable',
          params: [
            {
              ACCOUNT: email
            }
          ],
          onSuccess: function (result) {
            var responseData = result.getValue();

            if (responseData === 0) {
              forgotPasswordCopy.addClass('hidden');
              document.location.href = '/account/password_request.tmpl?PC_EMAIL_ADDRESS=' + encodeURIComponent(email);
            } else {
              if (panel) {
                site.checkout.panel.clearPanelMessages();
                panel.setMessages(
                  {
                    text: site.signin.forgotPasswordEmailNotFound
                  },
                  true
                );
              } else {
                errorListNode[0].innerHTML = '';
                forgotPasswordCopy.removeClass('hidden');
                generic.showErrors(
                  [
                    {
                      text: site.signin.forgotPasswordEmailNotFound
                    }
                  ],
                  errorListNode
                );
                generic.showErrorState(emailNode[0]);
              }
            }
          },
          onFailure: function () {
            if (panel) {
              panel.setMessages({
                text: site.signin.forgotPasswordNoEmailProvided
              });
            } else {
              generic.showErrors(
                [
                  {
                    text: site.signin.forgotPasswordNoEmailProvided
                  }
                ],
                errorListNode
              );
              generic.showErrorState(emailNode[0]);
            }
          }
        });
      });
    },
    initSignInForm: function () {
      var self = this;
      var $login = $('.login', self.$template);
      var $smsOTP = $('.sms-otp', self.$template);
      var $form = $('#login__form', $login);
      var $submit = $('.js-signin-submit', $login);
      var $smsOTPSwitch = $('.js-sms-otp-switch', $login);
      var $returnUrl = $('input[name="RETURN_URL"]', $form);
      var $forgotPassword = $('.js-gnav-forgot-password', $login);
      var $errorNode = $('p#gnav-lpw-text', $login);
      var $forgotNote = $('p#gnav_forgot_pw_note');

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');

      if ($forgotPassword.length > 0) {
        site.signin.forgotPasswordReturnuser({
          resetPassword: true,
          emailNode: $('.js-login-email-phone', $login),
          errorListNode: $errorNode,
          forgotPasswordLink: $forgotPassword,
          forgotPasswordNote: $forgotNote
        });
      }

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $smsOTPSwitch.click(function () {
        $login.addClass('hidden');
        $smsOTP.removeClass('hidden');
      });
    },
    initSMSForm: function () {
      var self = this;
      var $smsOTP = $('.sms-otp', self.$template);
      var $login = $('.login', self.$template);
      var $signinNewMobileForm = $('.js-signin-new-mobile', $smsOTP);
      var $form = $('.js-sms-pincode', $smsOTP);
      var $showPass = $('input[name="show_pass"]', $smsOTP);
      var $password = $('input[name="PASSWORD"]', $smsOTP);
      var $submit = $('.js-sms-otp-submit', $smsOTP);
      var $loginSwitch = $('.js-login-switch', $smsOTP);
      var $returnUrl = $('input[name="RETURN_URL"]', $smsOTP);

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');
      if ($.cookie('csrftoken')) {
        var token = '<input type="hidden" name="_TOKEN" value="' + $.cookie('csrftoken') + '" />';

        $form.append(token);
        $signinNewMobileForm.append(token);
      }

      $showPass.removeAttr('checked');
      $showPass.click(function () {
        var isChecked = $(this).is(':checked');

        $password[0].type = isChecked ? 'text' : 'password';
      });

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $loginSwitch.click(function () {
        $smsOTP.addClass('hidden');
        $login.removeClass('hidden');
      });
    },

    initAccountLinks: function () {
      var self = this;
      var $login = $('.login', self.$template);
      var $smsOTP = $('.sms-otp', self.$template);
      var $account = $('.account-links', self.$template);
      var $welcome = $('.account-links__welcome', self.$template);
      var $userName = $('.account-links__name', self.$template);

      $(self.$template).addClass('is_signed_in');
      var user = site.userInfoCookie.getValue('first_name');

      if (!_.isUndefined(user) && !_.isNull(user)) {
        $userName.html(user);
        $welcome.show();
      } else {
        $welcome.hide();
      }
      $login.hide();
      $smsOTP.hide();
      $account.show();
    },

    initTCaptcha: function () {
      var self = this;

      if (typeof TencentCaptcha === 'undefined') {
        var body = document.getElementsByTagName('body')[0];
        var script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js';
        script.onreadystatechange = self.scriptLoadedTCaptcha;
        script.onload = self.scriptLoadedTCaptcha;
        body.appendChild(script);
      } else {
        self.scriptLoadedTCaptcha();
      }
    },

    scriptLoadedTCaptcha: function () {
      var $captcha_field = $('#js-gnav-sms-pincode-captcha');
      var $captcha_form = $captcha_field.closest('form');
      var $captcha_submit = $captcha_form.find(':submit');
      var $captcha_error = $captcha_form.find('.js-signin-errors');
      var $document = $(document);
      var biz_state = $captcha_form.attr('id') + '_' + parseInt(Math.random() * (9999999999 - 100000000) + 100000000);

      if (typeof $.fn.serializeObject === 'undefined') {
        $.fn.serializeObject = function () {
          var o = {};
          var a = this.serializeArray();

          $.each(a, function () {
            if (o[this.name]) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });

          return o;
        };
      }

      $captcha_submit.on('click', function (evt) {
        evt.preventDefault();
        var $submit_btn = $(this);
        var btn_value = $submit_btn.val();
        var btn_width = $submit_btn.width();
        var btn_height = $submit_btn.height();

        $submit_btn.width(btn_width).height(btn_height).val(' ').addClass('loading').prop('disabled', true);
        // convert to string
        var app_id = '' + $captcha_submit.data('app-id');
        var tcaptcha = new TencentCaptcha(
          app_id,
          function (res) {
            if (res.bizState === biz_state && res.ret === 0) {
              $document.trigger('TCaptcha_GNAV.success');
              $captcha_field.val(btoa(res.ticket + ' ' + res.randstr));
              var formData = $captcha_form.serializeObject();

              generic.jsonrpc.fetch({
                method: 'rpc.form',
                params: [formData],
                onSuccess: function (jsonRpcResponse) {
                  $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
                  var resultData = jsonRpcResponse.getData();

                  $document.trigger('SMS_OTP_GNAV.success', [resultData]);
                },
                onFailure: function (ss) {
                  $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
                  var errorObjectsArray = ss.getMessages();

                  $document.trigger('SMS_OTP_GNAV.failure', [errorObjectsArray]);
                }
              });
            } else {
              $submit_btn.removeClass('loading').val(btn_value).prop('disabled', false);
            }
          },
          { bizState: biz_state }
        );

        tcaptcha.show();
      });
    },

    initSigninForms: function () {
      var $document = $(document);
      var $overlay = $('.js-signin-block');
      var $signin_form = $overlay.find('form[name=signin]');
      var $sms_form = $overlay.find('form[name=signin_new_mobile]');
      var $otp_signin_form = $overlay.find('form[name=sms_pincode]');
      var $signin_section = $signin_form.closest('section');
      var $sms_section = $sms_form.closest('section');
      var $sms_errors = $sms_form.find('.js-signin-errors');
      var $link_signin_form = $('.js-signin-form-link a');
      var $link_sms_otp_form = $('.js-sms-otp-form-link a');
      var $sms_sent_message = $otp_signin_form.find('.js-sms-otp-sent');
      var $privacy = $overlay.find('.privacy-policy');

      $link_signin_form.on('click', function () {
        $sms_section.not('.hidden').addClass('hidden');
        $signin_section.removeClass('hidden');
      });

      $link_sms_otp_form.on('click', function () {
        $signin_section.not('.hidden').addClass('hidden');
        $sms_section.removeClass('hidden');
      });

      $document.on('SMS_OTP_GNAV.success', function (evt, d) {
        $sms_errors.empty();
        $sms_sent_message.removeClass('hidden');
        $sms_form.not('hidden').addClass('hidden');
        $otp_signin_form.removeClass('hidden');
        if (d.is_return_user) {
          $privacy.not('.hidden').addClass('hidden');
        } else {
          $privacy.removeClass('hidden');
        }
      });

      $document.on('SMS_OTP_GNAV.failure', function (evt, errors) {
        $sms_sent_message.not('.hidden').addClass('hidden');
        $sms_errors.empty();
        $.each(errors, function (idx, e) {
          var $li = $('<li class="server"></div>');

          $li.attr('id', e.key).html(e.text);
          $sms_errors.append($li);
        });
      });
    },

    init: function () {
      var self = this;

      if (site.signin.isSignedIn === '0') {
        self.initTCaptcha();
        self.initSigninForms();
        self.initSignInForm();
        self.initSMSForm();
      } else if (site.signin.isSignedIn === '1') {
        self.initAccountLinks();
      }
    }
  };

  Drupal.behaviors.gnavAccountV1 = {
    attach: function (context) {
      site.signin.isSignedIn =
        typeof site.userInfoCookie === 'undefined' ||
        _.isUndefined(site.userInfoCookie.getValue('signed_in')) ||
        _.isNull(site.userInfoCookie.getValue('signed_in'))
          ? 0
          : site.userInfoCookie.getValue('signed_in');
      site.signin.$template = $('.js-signin-block', context);
      site.signin.init();

      var $thisContent = $('.js-gnav-util-trigger-account').next('.js-gnav-util-content');

      $(document)
        .on('mouseover', '.js-site-header-utility-not-signed-in', function (event) {
          event.preventDefault();
          if ($('.gnav-util--account').length) {
            $('body').removeClass('mobile-gnav-active');
            $thisContent.removeClass('hidden');
          }
        })
        .on('mouseout', '.gnav-util__content--account', function (event) {
          if (!$(event.target).is('input, span')) {
            $(this).addClass('hidden');
          }
        });
    }
  };
})(jQuery);
